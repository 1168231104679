"use client";

import React, { useCallback, useState } from "react";
import { Maybe } from "../../types/Types";
import { UserContext } from "./UserContext";
import { isUserWithRole, TUser } from "@/types/PlaybookTypes";
import { decodeMicrosoftIdToken } from "@/utils/authUtils";
import { createOrUpdateUser } from "@/api/PlaybookAPI";

export function UserProvider({ children }: { children: React.ReactNode }): React.JSX.Element {
  const [stateUser, setStateUser] = useState<Maybe<TUser>>(getUserFromLocalStorage());

  const setUser = useCallback((user: Maybe<TUser>) => {
    if (!localStorage) return;

    setStateUser(user);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, []);

  const updateUserToMatchToken = useCallback(
    async (token: string): Promise<TUser> => {
      // Check if the current user has the same ID as this token
      if (stateUser && stateUser.microsoftID === decodeMicrosoftIdToken(token)?.oid) {
        return stateUser;
      }

      // Otherwise we need to fetch the user from the API
      const user = await createOrUpdateUser({ idToken: token });

      // Store the user in the cache
      setUser(user);

      // Return the user
      return user;
    },
    [setUser, stateUser],
  );

  return (
    <UserContext.Provider value={{ user: stateUser, setUser, updateUserToMatchToken }}>{children}</UserContext.Provider>
  );
}

function getUserFromLocalStorage(): Maybe<TUser> {
  if (!localStorage) return null;

  // Fetch the user from local storage
  const userJson = localStorage.getItem("user");
  if (userJson === null) return null;

  // Parse the user from local storage
  let user: any;
  try {
    user = JSON.parse(userJson);
  } catch (e) {
    if (e instanceof SyntaxError && e.message.includes("JSON Parse error")) {
      // Someone messed with the local storage
      localStorage.removeItem("user");
      return null;
    } else {
      // Something else went wrong, so throw the error
      throw e;
    }
  }

  // Check if the user is valid. Clear local storage if it's not.
  if (!isUserWithRole(user)) {
    localStorage.removeItem("user");
    return null;
  }

  // Return the user
  return user;
}
