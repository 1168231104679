import { createContext } from "react";

export type TErrorModalSettings = {
  errorTitle: string;
  errorBody: string;
};

export type TShowErrorModal = (errorData: TErrorModalSettings) => void;

type ErrorHandlingContextProps = {
  showErrorModal: TShowErrorModal;
  hideErrorModal: () => void;
};

const defaultErrorModalContextValue: ErrorHandlingContextProps = {
  showErrorModal: () => {},
  hideErrorModal: () => {},
};

export const ErrorModalContext = createContext(defaultErrorModalContextValue);
