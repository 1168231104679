import { createContext } from "react";
import { Maybe } from "../../types/Types";
import { TOrganization } from "../../types/OrganizationsTypes";

type OrganizationContextProps = {
  organization: Maybe<TOrganization>;
  setOrganization: (_: Maybe<TOrganization>) => void;
};

const defaultOrganizationContextValue: OrganizationContextProps = {
  organization: null,
  setOrganization: (_: Maybe<TOrganization>) => {},
};

export const OrganizationContext = createContext(defaultOrganizationContextValue);
