"use client";

import React, { useState } from "react";
import { ErrorModalContext, TErrorModalSettings, TShowErrorModal } from "./ErrorModalContext";
import { PincitesErrorDialog } from "../../components/library/PincitesErrorDialog";

export function ErrorModalProvider({ children }: { children: React.ReactNode }): React.JSX.Element {
  const [modalErrorData, setModalErrorData] = useState<{
    isOpen: boolean;
    errorModalData: TErrorModalSettings;
  }>({
    isOpen: false,
    errorModalData: {
      errorTitle: "",
      errorBody: "",
    },
  });

  const showErrorModal: TShowErrorModal = (errorData: TErrorModalSettings) => {
    setModalErrorData({ isOpen: true, errorModalData: errorData });
  };

  const hideErrorModal = () => {
    setModalErrorData({
      isOpen: false,
      errorModalData: { errorTitle: "", errorBody: "" },
    });
  };

  return (
    <ErrorModalContext.Provider value={{ showErrorModal, hideErrorModal }}>
      {children}
      {modalErrorData.isOpen && (
        <PincitesErrorDialog
          isOpen={modalErrorData.isOpen}
          errorTitle={modalErrorData.errorModalData.errorTitle}
          errorBody={modalErrorData.errorModalData.errorBody}
          onClose={hideErrorModal}
        />
      )}
    </ErrorModalContext.Provider>
  );
}
