import * as Sentry from "@sentry/react";
import { decodeMicrosoftIdToken } from "./authUtils";

export function initializeSentry() {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ? "staging" : process.env.NEXT_PUBLIC_VERCEL_ENV,
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "development",
  });
}

export function tagSentryWithUser({ token }: { token: string }): void {
  const decodedToken = decodeMicrosoftIdToken(token);

  if (decodedToken) {
    Sentry.getGlobalScope().setUser({
      email: decodedToken.email,
      name: decodedToken.name,
    });
  }
}

export function untagSentryUser(): void {
  Sentry.getGlobalScope().setUser(null);
}

export function captureException({ error, metadata }: { error: Error; metadata?: Record<string, any> }): void {
  Sentry.captureException(error, { extra: metadata });
}
