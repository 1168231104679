"use client";

import React, { useState } from "react";
import { Maybe } from "../../types/Types";
import { OrganizationContext } from "./OrganizationContext";
import { isOrganization, TOrganization } from "../../types/OrganizationsTypes";

export function OrganizationProvider({ children }: { children: React.ReactNode }): React.JSX.Element {
  const [stateOrganization, setStateOrganization] = useState<Maybe<TOrganization>>(getOrganizationFromLocalStorage());

  const setOrganization = (organization: Maybe<TOrganization>) => {
    setStateOrganization(organization);

    if (!localStorage) return;
    if (organization) {
      localStorage.setItem("organization", JSON.stringify(organization));
    } else {
      localStorage.removeItem("organization");
    }
  };

  return (
    <OrganizationContext.Provider value={{ organization: stateOrganization, setOrganization }}>
      {children}
    </OrganizationContext.Provider>
  );
}

function getOrganizationFromLocalStorage(): Maybe<TOrganization> {
  if (!localStorage) return null;

  // Fetch the organization from local storage
  const organizationJson = localStorage.getItem("organization");
  if (organizationJson === null) return null;

  // Parse the organization from local storage
  let organization;
  try {
    organization = JSON.parse(organizationJson);
  } catch (e) {
    if (e instanceof SyntaxError && e.message.includes("JSON Parse error")) {
      // Someone messed with the local storage
      organization = null;
    } else {
      // Something else went wrong, so throw the error
      throw e;
    }
  }

  // Check if the organization is valid. Clear local storage if it's not.
  if (organization === null || !isOrganization(organization)) {
    localStorage.removeItem("organization");
    return null;
  }

  // Return the organization
  return organization;
}
