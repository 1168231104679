import React, { ReactNode } from "react";
import { Field, FieldProps, makeStyles, mergeClasses } from "@fluentui/react-components";

export enum PincitesFieldValidationState {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  NONE = "none",
}

export type PincitesFieldValidationConfig = {
  validationMessage: string;
  validationState?: PincitesFieldValidationState;
  validationIcon?: React.JSX.Element;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "auto",
    // Align the validation message and the validation icon
    '& [id$="__validationMessage"]': {
      display: "flex",
      alignItems: "center",
    },
  },
  fullWidth: {
    width: "100%",
  },
  halfWidth: {
    width: "50%",
  },
});

export enum PincitesFieldSize {
  FULL = "FULL",
  HALF = "HALF",
  AUTO = "AUTO",
}

export function PincitesField({
  label,
  hint,
  required,
  validationMessage,
  validationState = PincitesFieldValidationState.NONE,
  validationIcon,
  size = PincitesFieldSize.FULL,
  children,
}: {
  label?: string | React.JSX.Element;
  hint?: string;
  required?: boolean;
  validationMessage?: string;
  validationState?: PincitesFieldValidationState;
  validationIcon?: React.JSX.Element;
  size?: PincitesFieldSize;
  children?: ReactNode;
}): React.JSX.Element {
  const styles = useStyles();
  const fieldClasses = mergeClasses(
    styles.root,
    size === PincitesFieldSize.FULL ? styles.fullWidth : size === PincitesFieldSize.HALF ? styles.halfWidth : "",
  );

  return (
    <Field
      label={{
        weight: "semibold",
        children: label,
      }}
      hint={hint}
      validationState={getValidationStateFromPinciteValidationState(validationState)}
      validationMessageIcon={validationIcon}
      validationMessage={validationMessage}
      required={required}
      className={fieldClasses}
    >
      {children}
    </Field>
  );
}

function getValidationStateFromPinciteValidationState(
  validationState: PincitesFieldValidationState,
): FieldProps["validationState"] {
  switch (validationState) {
    case PincitesFieldValidationState.ERROR:
      return "error";
    case PincitesFieldValidationState.SUCCESS:
      return "success";
    case PincitesFieldValidationState.WARNING:
      return "warning";
    case PincitesFieldValidationState.NONE:
      return "none";
    default:
      return "none";
  }
}
