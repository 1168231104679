import { createContext } from "react";
import { Maybe } from "../../types/Types";
import { TUser } from "@/types/PlaybookTypes";

type UserContextProps = {
  user: Maybe<TUser>;
  setUser: (_: Maybe<TUser>) => void;
  updateUserToMatchToken: (_: string) => Promise<TUser>;
};

export const defaultUserContextValue: UserContextProps = {
  user: null,
  setUser: (_: Maybe<TUser>) => {},
  updateUserToMatchToken: async (_: string) => {
    return {
      id: "",
      microsoftID: "",
      email: "",
      name: "",
      role: null,
      createdAt: "",
      updatedAt: "",
    };
  },
};

export const UserContext = createContext(defaultUserContextValue);
