"use client";

import "./globals.css";
import React from "react";
import dynamic from "next/dynamic";
const MediaQuery = dynamic(() => import("react-responsive"), {
  ssr: false,
});
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../config/msalConfig";
import { ReactNode } from "react";
import { initializeSentry, captureException } from "../utils/sentry";
import * as Sentry from "@sentry/react";
import { ErrorModalProvider } from "../contexts/error_modal/ErrorModalProvider";
import { OrganizationProvider } from "../contexts/organization/OrganizationProvider";
import { SWRConfig } from "swr";
import axios from "axios";
import { FluentProvider, webDarkTheme, webLightTheme } from "@fluentui/react-components";
import { useRouter } from "next/navigation";
import { ActiveAccountChangedError, NoActiveAccountError, REDIRECT_KEY } from "../utils/authUtils";
import { UserProvider } from "@/contexts/user/UserProvider";

const msalInstance = new PublicClientApplication(msalConfig);

export function AppWrapper({ children }: { children: ReactNode }) {
  initializeSentry();
  const router = useRouter();

  return (
    <MsalProvider instance={msalInstance}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <SWRConfig
          value={{
            onError: (error, key) => {
              // Log the error to the console
              console.log(key, error);
              console.log(JSON.stringify(error));

              // Ignore network errors and 4xx errors
              if (
                axios.isAxiosError(error) &&
                (error.code === "ERR_NETWORK" ||
                  error.code === "ECONNABORTED" ||
                  (error.response?.status && error.response?.status >= 400 && error.response?.status < 500))
              ) {
                return;
              }

              if (
                error instanceof InteractionRequiredAuthError ||
                error instanceof NoActiveAccountError ||
                error instanceof ActiveAccountChangedError
              ) {
                // If we get an interaction required error we know we need to
                // redirect the user to the login page
                sessionStorage.setItem(REDIRECT_KEY, window.location.pathname + window.location.search);
                router.push("/");
                return;
              }

              // Log the error to Sentry
              captureException({
                error,
                metadata: {
                  swrKey: key,
                },
              });
            },
          }}
        >
          <MediaQuery query="(prefers-color-scheme: dark)">
            {(prefersDark) => (
              <FluentProvider theme={prefersDark ? webDarkTheme : webLightTheme}>
                <UserProvider>
                  <OrganizationProvider>
                    <ErrorModalProvider>{children}</ErrorModalProvider>
                  </OrganizationProvider>
                </UserProvider>
              </FluentProvider>
            )}
          </MediaQuery>
        </SWRConfig>
      </Sentry.ErrorBoundary>
    </MsalProvider>
  );
}

function ErrorFallback(): React.JSX.Element {
  return <p>Oops! Looks like something went wrong. Hang tight while we work on a fix.</p>;
}
