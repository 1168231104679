import { Maybe } from "./Types";

export enum DefaultUserRole {
  ADMIN = "admin",
  CONTRACT_REVIEWER = "contract_reviewer",
  NONE = "none",
}

export type TOrganization = {
  id: string;
  name: string;
  isPersonal: boolean;
  defaultRoleForNewUsers: DefaultUserRole;
  createdAt: string;
  updatedAt: string;
  trialEndDate: Maybe<string>;
  disabledAt: Maybe<string>;
  isPincites: Maybe<boolean>;
};

export function isOrganization(arg: any): arg is TOrganization {
  return (
    arg &&
    typeof arg?.id === "string" &&
    typeof arg?.name === "string" &&
    typeof arg?.isPersonal === "boolean" &&
    typeof arg?.defaultRoleForNewUsers === "string" &&
    typeof arg?.createdAt === "string" &&
    typeof arg?.updatedAt === "string" &&
    (arg?.trialEndDate === null || typeof arg?.trialEndDate === "string") &&
    (arg?.disabledAt === null || typeof arg?.disabledAt === "string") &&
    ["boolean", "undefined"].includes(typeof arg?.isPincites)
  );
}

export type TMicrosoftOrganization = {
  id: string;
  displayName: string;
  verifiedDomains: string[];
};

export function getMicrosoftOrganizationFromAPIResponse(response: any): TMicrosoftOrganization {
  return {
    id: response.id,
    displayName: response.displayName,
    verifiedDomains: response.verifiedDomains.map((domain: any) => domain.name),
  };
}
